.mickies-hours {
  margin: 150px auto 50px;
  font-family: "Marcellus SC";
}

.mickies-hours > h2 {
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Amita";
  margin-bottom: -20px;
}

.closed {
  font-size: 30px;
  margin-bottom: -20px;
}

hr.solid {
  margin: 15px auto;
  border-top: 2px solid;
  width: 100px;
}

.takeout-only {
  font-size: 20px;
}

.current-day {
  font-size: 20px;
}

@media only screen and (max-width: 800px) {
  .mickies-hours > h2 {
    font-size: 30px;
    margin-bottom: -10px;
  }
  .takeout-only {
    font-size: 18px;
  }
  .mickies-hours {
    margin: 120px auto 40px;
  }
  .closed {
    font-size: 24px;
  }
}

@media only screen and (max-width: 500px) {
  .mickies-hours > h2 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .takeout-only {
    font-size: 16px;
  }
  .current-day {
    font-size: 16px;
  }
  .mickies-hours {
    margin: 100px auto 20px;
  }
  .closed {
    font-size: 16px;
  }
}
