a {
  color: inherit;
  text-decoration: none;
}

.menu {
  position: fixed;
  height: 100px;
  top: 0;
  width: 100%;
  color: rgb(32, 32, 32);
  -webkit-box-shadow: 0px 8px 7px -5px #d7d7d7;
  -moz-box-shadow: 0px 8px 7px -5px #d7d7d7;
  box-shadow: 0px 8px 7px -5px #d7d7d7;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: #c4012f;
  z-index: 100;
}

.mickies {
  font-family: "Amita";
  font-weight: 900;
}

.menu > div:first-child {
  color: #ffffff;
  text-shadow: -1.5px 1.5px 3px #000, 1.5px 1.5px 3px #000, 1.5px -1.5px 0 #000,
    -1.5px -1px 0 #000;
  font-size: 40px;
  margin: 10px 20px;
  display: inline-block;
  float: left;
}
.menu > div:nth-child(2) {
  margin: 35px 30px;
  display: inline-block;
  float: right;
}
.desktop-menu {
  float: right;
  margin-top: 25px;
  margin-right: 15px;
  display: inline-block;
}

.mobile-menu {
  float: right;
  margin-top: 13px;
  margin-right: 15px;
  display: inline-block;
}

@media only screen and (min-width: 801px) {
  .mobile-menu {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .desktop-menu {
    display: none;
  }
  .menu {
    height: 80px;
  }
  .menu > div:first-child {
    font-size: 32px;
    margin: 18px 10px;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000,
      -1px -1px 0 #000;
  }
  .menu > div:nth-child(2) {
    margin: 25px 20px;
  }
}

@media only screen and (max-width: 601px) {
  .menu {
    height: 60px;
  }
  .menu > div:first-child {
    font-size: 24px;
    margin: 8px 10px;
  }
  .menu > div:nth-child(2) {
    margin: 10px 10px;
  }
  .menu > div:nth-child(2) {
    display: none;
  }
}
