.footer {
  position: relative;
  z-index: 1000;
  height: 150px;
  background-color: #c4012f;
  color: #ffffff;
  margin-top: 60px;
}

.mickies {
  font-family: "Amita";
  font-weight: 900;
}

.footer-logo {
  vertical-align: top;
  margin-top: 40px;
  font-size: 32px;
  text-shadow: -1.5px 1.5px 3px #000, 1.5px 1.5px 3px #000, 1.5px -1.5px 0 #000,
    -1.5px -1.5px 0 #000;
  display: inline-block;
}

.top-shadow {
  width: 100%;
  overflow-y: hidden;
  padding: 0 0 10px;
}
.top-shadow > div {
  width: 100%;
  box-shadow: 0px 0px 6px 3px #676767;
  -moz-box-shadow: 0px 0px 6px 3px #676767;
  -webkit-box-shadow: 0px 0px 6px 3px #676767;
}

@media only screen and (max-width: 700px) {
  .footer-logo {
    margin-top: 40px;
    font-size: 28px;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000,
      -1px -1px 0 #000;
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    height: 110px;
    margin-top: 40px;
  }
  .footer-logo {
    margin-top: 10px;
    font-size: 24px;
  }
}
