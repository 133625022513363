.container {
  margin: -20px auto 60px;
  font-family: "Marcellus SC";
}
.media-link {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: -10px auto 50px;
  }
  .media-link {
    font-size: 16px;
  }
}
