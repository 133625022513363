.mickies-menu {
  margin: 40px auto 30px;
  font-family: "Marcellus SC";
}

.container {
  text-align: center;
  margin: auto;
  width: 90%;
  max-width: 1400px;
}
.container > h2 {
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Amita";
  margin-bottom: -20px;
}

.category {
  margin-top: 40px;
  margin: 20px auto 0;
}

.category > h2 {
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Amita";
  margin-bottom: -20px;
}

hr.solid {
  margin: 15px auto;
  border-top: 2px solid;
  width: 100px;
}

.sub-category {
  text-transform: uppercase;
  margin: 50px auto;
  font-size: 24px;
  font-family: "Marcellus SC";
  border: 2px solid black;
}
.sub-category > p {
  text-transform: none;
  font-size: 20px;
}
.item-container {
  text-transform: uppercase;
  font-family: "Marcellus SC";
  font-size: 14px;
  overflow: hidden;
}
.item {
  width: 500px;
  vertical-align: text-top;
  margin: 10px 20px;
  display: inline-block;
}
.item > div > b {
  font-size: 20px;
}
.item > div > b:first-child {
  float: left;
}
.item > div > b:nth-child(2) {
  float: right;
  margin-right: 5px;
}
.item > p {
  margin-top: 30px;
  font-family: "Marcellus SC";
  clear: both;
  text-align: left;
}

.omelet-ingredients {
  font-size: 14px;
  margin-bottom: 40px;
}
.omelet-ingredients > h4 {
  margin-bottom: 5px;
}
@media only screen and (max-width: 800px) {
  .mickies-menu {
    margin: 30px auto 20px;
  }
  .category > h2 {
    font-size: 30px;
    margin-bottom: -10px;
  }
  .sub-category {
    margin: 40px auto;
    font-size: 20px;
  }
  .sub-category > p {
    font-size: 12px;
  }
  .item-container {
    font-size: 12px;
  }
  .item {
    width: 450px;
  }
  .item > div > b {
    font-size: 14px;
  }
  .omelet-ingredients {
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .mickies-menu {
    margin: 20px auto;
  }
  .category > h2 {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .sub-category {
    margin: 20px auto;
    font-size: 14px;
  }
  .sub-category > p {
    font-size: 10px;
  }
  .item {
    width: 280px;
    margin: 4px auto 6px auto;
  }
  .item > div > b {
    font-size: 12px;
  }
  .item-container {
    font-size: 10px;
  }
  .omelet-ingredients {
    font-size: 10px;
  }
}
